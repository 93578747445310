import { Box, Text } from "rebass";
import { useIntl } from "../providers/Intl"

export const NotFound = () => {
  const intl = useIntl();


  return (
    <Box p={25}>
      <Text as="h1">{intl.general.notFound}</Text>
    </Box>
  )
}