import theme from './theme';

import { ThemeProvider } from 'theme-ui';
import { IntlProvider } from './providers/Intl';
import Layout from './components/Layout';
import LanguagePicker from './components/LanguagePicker';
import {  Route, Switch } from "react-router";
import Status from './components/Status';
import { NotFound } from './components/404';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider>
        <Layout >
          <LanguagePicker />
          <Switch>
            <Route path="/vaccine-status/:id"><Status /></Route>
            <Route component={NotFound} />
          </Switch>
        </Layout>
   
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
