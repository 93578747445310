import { LANGUAGE } from './config'
import afrikaans from './intl/afrikaans.json'
import english from './intl/english.json'


export const intl = {
  [LANGUAGE.afrikaans]: afrikaans,
  [LANGUAGE.english]: english
}

