import { useParams, Redirect } from "react-router-dom";
import { useVaxStatus } from "../providers/VaxStatus";
import Loader from "react-loader-spinner";
import theme from "../theme";
import { Box, Text } from "rebass";
import { useIntl } from "../providers/Intl";
const Status = () => {
  const {id} = useParams();
  const { loading, vaxStatus, hasLoaded , error} = useVaxStatus(id);
  const intl = useIntl();

  if(hasLoaded && (vaxStatus.message || error)) {
    return <Redirect to="/404" />
  }

  return (<Box sx={{paddingTop: [40]}}>
    <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: [0, 10, 100]}}>
    {
      loading &&
      <>
      <Loader
        type="TailSpin"
        color={theme.colors.primary}
        height={100}
        width={100}
        timeout={3000} //3 secs
        style={{margin: '0 auto'}}
        /> 
      <Text sx={{fontSize: [20, 20, 30]}}>{intl.general.loading}</Text>
      </>
    }
    {!loading && hasLoaded && !error && (
      <Box fontSize={25}>
        <Text color={theme.colors.primary}>{intl.vaccineStatus.name}</Text>
        <Text fontSize={40} mb={19}>{vaxStatus.fullName}</Text>
        <Text color={theme.colors.primary}>Status</Text>
        <Text fontSize={50} mb={19} color={vaxStatus.vStatus.total > 1 ? theme.colors.primary: 'black'}>{vaxStatus.vStatus.total > 1 ? intl.vaccineStatus.fullStatus: intl.vaccineStatus.notFull}</Text>
        <hr />
        <Text color={theme.colors.primary}>{intl.vaccineStatus.vaccines}</Text>

        <ul style={{listStyle: 'none', paddingLeft: '0'}}>
          {vaxStatus.vStatus.vaccines.map((status, ind) => (

            <Text as="li" key={status.producer + ind} sx={{fontSize: 16, paddingBottom: '20px'}} >
                <Text color={theme.colors.primary} pb="5px"> {ind + 1}: {intl.vaccineStatus.date}: {status.date}</Text>
                {status.commonNames.join(', ')}
              </Text>
          ))}
        </ul>
      </Box>
    )}
    </Box>
    
    </Box>)
}

export default Status;