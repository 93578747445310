import React, { useCallback, useState } from 'react';
import axiosInstance from '../axios';

const VaxStatusContext = React.createContext();

function VaxStatusProvider({ children }) {
  const [vaxStatus, setVaxStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const getVaxStatus = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: '/vaccination-status/'+id,
      });
      
      if(response.status !== 200) {
        setError(new Error(`Server responded with ${response.status}`))
      }
      
      setVaxStatus(
        response.data
        );
      } catch (error) {
        setError(error);
      } finally {
      setTimeout(() => {

        setLoading(false);
        setHasLoaded(true);
      }, 1000)
    }
  }, []);

  const value = {
    vaxStatus,
    loading,
    hasLoaded,
    error,
    getVaxStatus,
  };

  return (
    <VaxStatusContext.Provider value={value}>
      {children}
    </VaxStatusContext.Provider>
  );
}

/**
 * @returns {{
 *  error: Error,
 *  getPackages: () => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  packages: object[],
 * }}
 */
function useVaxStatus(id) {
  const context = React.useContext(VaxStatusContext);

  if (context === undefined) {
    throw new Error('useVaxStatus must be used within a VaxStatusProvider');
  }

  if (!context.loading && !context.hasLoaded) {
    context.getVaxStatus(id);
  }

  return context;
}

export { VaxStatusProvider, useVaxStatus };
