import {Box, Image, Text} from 'rebass';
import { useIntl } from '../providers/Intl';
import theme from '../theme';
import { getFontSize } from '../utils';
import flag from '../images/Flag_of_South_Africa.svg';

export const Header = () => {
  const intl = useIntl();
  return (
    <Box p={[20, 10, 20]} as="header" sx={{
      backgroundColor: theme.colors.primary,
      height: '80px',
      borderBottom: `2px solid ${theme.colors.secondary}`,
      display: 'flex',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      alignItems: 'center'
    }}>
      <Image src={flag} alt="Flag" width={80} mr={40} />
      <Text as="h1" color="white" mb={0} fontSize={[18, 18, getFontSize(38, 18, .25, intl.home.title)]}>{intl.home.title}</Text>
    </Box>
  )
}
export default Header