import { intl } from "../intl";


import React, { useState } from 'react';
import { default_langauge } from "../config";


const IntlContext = React.createContext();

function IntlProvider({ children }) {
  const [language, setLanguage] = useState(default_langauge);

  const value = {
    setLanguage, language
  };

  return (
    <IntlContext.Provider value={value}>
      {children}
    </IntlContext.Provider>
  );
}

/**
 * @returns {{
 *  error: Error,
 *  getPackages: () => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  packages: object[],
 * }}
 */
function useIntl() {
  const context = React.useContext(IntlContext);

  if (context === undefined) {
    throw new Error('useIntl must be used within a IntlProvider');
  }

  return intl[context.language];
}

export { IntlProvider, useIntl, IntlContext };
