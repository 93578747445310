import { Box } from "rebass"
import Header from "./Header"

const Layout = ({children}) => (
  <Box sx={{ backgroundColor: "#f0f0f0", height: '100vh', paddingTop: '82px'}}>
    <Header />
    <Box as="main" maxWidth={800} mx="auto" sx={{
      padding: [20, 20, 45],
      height: '100vh',
      backgroundColor: "#ffffff",
    }}>
      {children}
    </Box>
  </Box>
)

export default Layout;