import {  useContext } from "react"
import { Box, Button } from "rebass";
import { LANGUAGE } from "../config";
import { IntlContext, useIntl } from "../providers/Intl"
import theme from "../theme";



const LanguageButton = ({active, ...props}) => (
  <Button {...props} mx={10} sx={{
    backgroundColor: 'white',
    outline: 'none',
    border: 'none',
    fontSize: [20],
    cursor: 'pointer',
    padding: 10,
    color: active ? theme.colors.primary : '#333333'
  }} />
)

const LanguagePicker = () => {
  const {language, setLanguage} = useContext(IntlContext);
  const intl = useIntl();
  const languageIds = Object.keys(LANGUAGE).map(l => LANGUAGE[l]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontSize: [20],
    }}>
      {languageIds.map(l => <LanguageButton onClick={() => setLanguage(l)} key={l} active={l === language}>{intl.general.languages[l]}</LanguageButton>)}
    </Box>
  )
}

export default LanguagePicker