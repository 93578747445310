import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BrowserRouter } from "react-router-dom";
import { VaxStatusProvider } from './providers/VaxStatus';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <VaxStatusProvider>

      <App />
      </VaxStatusProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
